<template>
  <!-- 视频信息 -->
  <div class="videoInfo">
    <div class="videoBox">
      <video :src="videoObj.VideoPath" width="100%" height="400" autoplay="autoplay" controls="controls"></video>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      videoObj: {},
    }
  },
  methods: {
    getInitData(id, pattemNmae) {
      let para = {
        LearningContentsID: id,
        ItemName: pattemNmae
      }
      this.$axios.post('api/LearningContents/LearningList', para).then(res => {
        this.videoObj = JSON.parse(res.data).data.VideoLearning;
      });
    },
  }
}
</script>

<style>
.videoInfo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background: black;
}
.videoBox{
  width: 80%;
  margin-left: 10%;
}
</style>