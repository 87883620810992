<template>
  <div class="home">
    <div class="leftNav" v-if="showType">
      <div class="leftBox">
        <div class="leftTitle">
          学习进度 {{learn}}/{{navList.length}}
        </div>
        <div class="btnList">
          <div @click="clickBack">
            <van-button type="default"><i class="el-icon-alituichu"></i><span style="color:#595959">退 出 训 练</span></van-button>
          </div>
          <div>
            <van-button type="default" v-if="tipsType" @click="lookBtn"><i class="el-icon-alimenzhenbaogao"></i><span style="color:#11ad91">{{tipsBtn}}</span></van-button>
            <van-button type="default" disabled v-if="!tipsType"><i class="el-icon-alimenzhenbaogao"></i><span style="color:#11ad91">{{tipsBtn}}</span></van-button>
          </div>
        </div>
        <ul class="listNav">
          <li v-for="(item,index) in navList" :key="index">
            <div class="titleHeader">{{item.Title}}</div>
            <div class="navContent">
              <div v-for="(item2,index2) in item.contentNav" :key="index2" @click="clickNav(item,item2)">{{item2}}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="showCss" @click="clickShow">
      <van-icon name="arrow-left" v-if="showType" />
      <van-icon name="arrow" v-if="!showType" />
    </div>
    <div class="displayBox">
      <template v-if="contentType">
        <contentInfo ref="contentRef"></contentInfo>
      </template>
      <template v-if="videoType">
        <videoInfo ref="videoRef"></videoInfo>
      </template>
      <template v-if="judegType">
        <judeg ref="judegRef" :trainItem=trainItem @completeObj=completeObj @affirm=affirm></judeg>
      </template>
      <template v-if="resType">
        <resultInfo :resultList=resultList></resultInfo>
      </template>
    </div>
  </div>
</template>

<script>
import judeg from "@/views/train/judgeQues.vue";
import resultInfo from "@/views/train/resultInfo.vue";
import contentInfo from "@/views/train/contentInfo.vue";
import videoInfo from "@/views/train/videoInfo.vue";
export default {
  data() {
    return {
      showType: true,
      judegType: false,
      userId: "",
      projectId: "",
      pattemNmae: "",
      navList: [],
      trainItem: "", // 左边列表训练
      learn: 0,
      trainId: "",
      tipsBtn: "完 成 训 练",
      tipsType: false,
      resultList: {},
      resType: false,
      contentType: false,
      videoType: false,
    }
  },
  components: {
    judeg,
    resultInfo,
    contentInfo,
    videoInfo,
  },
  methods: {
    initData() {
      let para = {
        UserID: this.userId,
        LearningProjectsID: this.projectId,
        ItemName: this.pattemNmae,
      };
      this.$axios.post('api/LearningContents/LearningContentsList', para).then(res => {
        let a = JSON.parse(res.data);
        if (a.code == 200) {
          this.navList = a.data;
          this.learn = 0;
          this.navList.forEach(v => {
            let arr = ["知识学习", "视频展示", "情景训练"];
            this.$set(v, "contentNav", arr);
            if (v.IsFinish) {
              this.learn++;
            }
          });
          if (this.learn == this.navList.length) {
            this.tipsType = true;
            this.resType = false;
          } else {
            this.tipsType = false;
            this.resType = false;
          }
        }
      });

      let para2 = {
        UserID: this.userId,
        LearningProjectsID: this.projectId,
        ItemName: this.pattemNmae,
      };
      this.$axios.post('api/LearningContents/PractiseLog', para2).then(res => {
        if (JSON.parse(res.data).code == 200) {
          this.resultList = JSON.parse(res.data).data;
          if (this.resultList == null) {
            this.tipsBtn = "完 成 训 练";
          } else {
            this.tipsBtn = "查 看 报 告";
          }
        }
      });

    },
    clickNav(item, item2) {
      this.trainItem = item;
      if (item2 == "情景训练") {
        this.judegType = true;
        this.contentType = false;
        this.videoType = false;
        if (!item.IsFinish) {
          this.$nextTick(() => {
            this.$refs.judegRef.getInitData(item.ID, this.pattemNmae);
          });
        } else {
          this.$nextTick(() => {
            this.$refs.judegRef.answerData(this.userId, item.ID, this.pattemNmae);
          });
        }
      } else if (item2 == "知识学习") {
        this.contentType = true;
        this.judegType = false;
        this.videoType = false;
        this.$nextTick(() => {
          this.$refs.contentRef.getInitData(item.ID, this.pattemNmae);
        });
      } else if (item2 == "视频展示") {
        this.videoType = true;
        this.judegType = false;
        this.contentType = false;
        this.$nextTick(() => {
          this.$refs.videoRef.getInitData(item.ID, this.pattemNmae);
        });
      } else {
        this.judegType = false;
        this.contentType = false;
        this.videoType = false;
      }
    },
    clickShow() {
      this.showType = !this.showType;
    },
    clickBack() {
      this.$router.push({ name: "pattern" });
    },
    completeObj() {
      this.initData();
    },
    affirm() {
      this.initData()
      this.judegType = false;
    },
    lookBtn() {
      if (this.tipsBtn == "完 成 训 练") {
        let para = {
          UserID: this.userId,
          LearningProjectsID: this.projectId,
          ItemName: this.pattemNmae,
        };
        this.$axios.post('api/LearningContents/SubmitPractise', para).then(res => {
          if (JSON.parse(res.data).code == 200) {
            this.tipsBtn = "查 看 报 告";
          }
        });
      } else {
        let para = {
          UserID: this.userId,
          LearningProjectsID: this.projectId,
          ItemName: this.pattemNmae,
        };
        this.$axios.post('api/LearningContents/PractiseLog', para).then(res => {
          if (JSON.parse(res.data).code == 200) {
            this.resultList = JSON.parse(res.data).data;
            this.resType = true;
          }
        });
      }
    },
  },
  mounted() {
    this.userId = this.cookies.kkGet("userId");
    this.projectId = this.cookies.kkGet("projectId");
    this.pattemNmae = this.cookies.kkGet("pattemNmae");
    this.judegType = false;
    this.initData();
  },
}
</script>

<style>
.home {
  position: relative;
}
.leftNav {
  width: 200px;
  height: 100vh;
  overflow: auto;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(105, 142, 176, 0.8);
}
.leftNav .leftTitle {
  padding: 20px;
  color: #fff;
  font-weight: bold;
}
.leftNav .btnList div {
  text-align: center;
}
.leftNav .btnList div .el-icon-alituichu {
  margin-right: 10px;
}
.leftNav .btnList div .el-icon-alimenzhenbaogao {
  margin-right: 10px;
  color: #11ad91;
}
.leftNav .btnList .van-button--default {
  background: rgba(255, 255, 255, 0.7);
  width: 168px;
  height: 30px;
  line-height: 30px;
  margin-top: 10px;
}
.leftNav .listNav {
  font-size: 13px;
  font-weight: bold;
  color: #fff;
  margin-top: 30px;
  padding: 0 20px;
}
.leftNav .listNav .titleHeader {
  padding: 5px 0;
}
.leftNav .listNav li {
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  margin-top: 10px;
}
.leftNav .listNav .navContent div {
  padding: 10px 15px;
}
.leftNav .listNav .navContent div:hover {
  background: rgba(118, 218, 200, 1);
}
.showCss {
  width: 30px;
  height: 30px;
  border: 1px solid #ddd;
  background: #fff;
  text-align: center;
  line-height: 34px;
  border-radius: 50%;
  position: fixed;
  top: 50vh;
  font-size: 12px;
  z-index: 1000;
}

.displayBox {
  width: 100%;
  height: 100vh;
}
</style>