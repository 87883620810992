<template>
  <div class="resultInfo">
    <!-- <div class="resMain">
      <div class="resTitle">训 练 报 告</div>
      <div class="resName">胸腔穿刺术——入门学习</div>
      <div class="resInfo">
        <span>训练情况：</span>
        <div class="tipsInfo">
          <span>在 ”胸前穿刺术——入门学习“ 的训练中，一共完成 13 个学习内容。</span>
          <div class="tipsBot">
            <div>
              <span>综合正确率：</span>
              <span style="font-size:18px;color:#1ABC9C;font-weight:bold;">{{resultList.CorrectRate}}%</span>
            </div>
            <div>
              <span>综合评价：</span>
              <span style="font-size:16px;color:#1ABC9C;font-weight:bold;">良好</span>
            </div>
            <div>
              <span>完成时间：</span>
              <span>{{resultList.AddTime}}</span>
            </div>
          </div>
        </div>
        <ul class="resList">
          <li>
            <div class="leftIcon">
              <i class="el-icon-alixunzhang"></i>
            </div>
            <div style="min-width:20px"></div>
            <div class="liMain">
              <div>
                <p style="color:#333;">查看病例</p>
                <p>学习内容</p>
              </div>
              <div>
                <p style="color:#1ABC9C;font-size:16px;font-weight:bold">80%</p>
                <p>正确率</p>
              </div>
              <div>
                <p style="color:#1ABC9C;font-size:16px;font-weight:bold">良好</p>
                <p>评价</p>
              </div>
            </div>
            <div class="rightLook">
              <span>查看详情</span>
            </div>
          </li>
        </ul>
      </div>
    </div> -->

    <div class="testImg">
      <img src="../../assets/img/testImg.png" alt="">
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: ["resultList"],
  methods: {},
  mounted() {
    // console.log(this.resultList);
  }
}
</script>

<style>
.resultInfo {
  width: 100%;
  height: 100%;
  padding: 20px 0;
}
.testImg {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
.resMain {
  width: 75%;
  height: 100%;
  margin-left: 12.5%;
  border: 1px solid #b2b3b4;
  overflow-y: auto;
}
.resultInfo .resTitle {
  font-family: "楷体 Bold", 楷体, sans-serif;
  font-size: 25px;
  color: #333;
  text-align: center;
  font-weight: bold;
  margin-top: 60px;
}
.resultInfo .resName {
  font-size: 18px;
  font-family: "楷体 Bold", 楷体, sans-serif;
  color: #333;
  text-align: center;
  margin-top: 20px;
}
.resultInfo .resInfo {
  padding: 0 15%;
  margin-top: 30px;
}
.resultInfo .tipsInfo {
  padding: 0 20px;
  margin-top: 10px;
}
.resultInfo .tipsBot {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.resultInfo .resList {
  margin-top: 30px;
}
.resultInfo .resList li {
  width: 100%;
  height: 64px;
  border: 1px solid #b2b3b4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: relative;
  margin-bottom: 20px;
}
.resultInfo .leftIcon {
  position: absolute;
  top: -10px;
  left: 0;
}
.resultInfo .leftIcon .el-icon-alixunzhang {
  color: #11ad91;
  font-size: 36px;
}
.resultInfo .rightLook {
  color: #169bd5;
}
.resultInfo .liMain {
  display: flex;
  justify-content: space-between;
  min-width: 75%;
}
.resultInfo .liMain div p:nth-of-type(2) {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
  text-align: center;
}
</style>