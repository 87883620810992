<template>
  <!-- 内容信息 -->
  <div class="contentINfo">
    <div class="mainInfo">
      <h2>{{contentObj.Title}}</h2>
      <p>{{contentObj.Contents}}</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      contentObj: {},
    }
  },
  methods: {
    getInitData(id, pattemNmae) {
      let para = {
        LearningContentsID: id,
        ItemName: pattemNmae
      }
      this.$axios.post('api/LearningContents/LearningList', para).then(res => {
        this.contentObj = JSON.parse(res.data).data.KnowledgeLearning;
      });
    },
  },
}
</script>

<style>
.contentINfo {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}
.mainInfo {
  width: 100%;
  height: 240px;
  background: #76dac8;
}
.mainInfo h2 {
  text-align: center;
  color: #fff;
  margin-top: 20px;
}
.mainInfo p {
  text-align: center;
  margin-top: 20px;
  color: #fff;
}
</style>